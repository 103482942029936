import ChatPreview from '@/common/components/ChatPreview';
import { LanguageContext } from '@/common/context/LanguageContext';
import { useChatsQuery } from '@/common/generated/graphql';
import { ApolloError } from '@apollo/client';
import Link from 'next/link';
import { transparentize } from 'polished';
import { FC, forwardRef, useContext } from 'react';
import styled from 'styled-components';
import Spinner from '../../design/components/spinner/Spinner';
import { Callout } from '../../design/components/textstyles';

const StyledChat = styled.section`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.CG7};
  width: 320px;
  max-height: calc(100vh - 52px);
  position: absolute;
  bottom: 0;
  left: 50%;
  box-shadow: 0 1px 8px 0 rgba(17, 17, 17, 0.16);
  border-radius: 2px;
  transform: translate(-50%, 100%);

  &:after {
    display: block;
    content: '';
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: -6px;
    border-bottom: 6px solid ${({ theme }) => theme.colors.CG7};
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobileMaxWidth}) {
    position: fixed;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 3;
    top: 50px;
    transform: none;
    max-height: none;

    &:after {
      display: none;
    }
  }
`;

const CenteredContainer = styled.div`
  height: 360px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  padding: 32px;
`;

const SeeAllButton = styled(Callout).attrs({ as: 'a' })`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  border-top: 1px solid ${({ theme }) => transparentize(0.92, theme.colors.CG1)};
`;

const Error: FC<{ error?: ApolloError }> = ({ error }) => {
  return <span>{error && error.message ? error.message : 'no data'}</span>;
};

const Chat = forwardRef<HTMLElement, { className?: string }>(
  ({ className }, ref) => {
    const { translate } = useContext(LanguageContext);
    const { data, loading, error } = useChatsQuery({
      fetchPolicy: 'cache-first',
      variables: { first: 5 },
    });

    return (
      <StyledChat ref={ref} className={className}>
        {loading ? (
          <CenteredContainer>
            <Spinner wait={100} />
          </CenteredContainer>
        ) : error || !data ? (
          <Error error={error} />
        ) : !data.viewer.chatsConnection.nodes.length ? (
          <CenteredContainer>
            <Callout>{translate('chat_messages_no_messages')}</Callout>
          </CenteredContainer>
        ) : (
          <>
            <div>
              {data.viewer.chatsConnection.nodes.map((chat) => (
                <ChatPreview key={chat.id} chat={chat} isSmall={true} />
              ))}
            </div>
            <Link
              href={{ pathname: '/inbox', query: { tab: 'chat' } }}
              passHref
            >
              <SeeAllButton>{translate('chat_show_all')}</SeeAllButton>
            </Link>
          </>
        )}
      </StyledChat>
    );
  }
);
Chat.displayName = 'Chat';

export default Chat;
