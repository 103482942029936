import { ExperimentContext } from '@/common/context/ExperimentContext';
import Link, { LinkProps } from 'next/link';
import { FC, PropsWithChildren, useContext } from 'react';

export type AuthLinkProps = Omit<LinkProps, 'as'> & {
  asPath?: string;
  isAuthorized: boolean;
} & PropsWithChildren;

const AuthLink: FC<AuthLinkProps> = ({
  href,
  asPath,
  children,
  isAuthorized,
  ...linkProps
}) => {
  const { preComms } = useContext(ExperimentContext);
  const pathname = preComms.isInVariant ? '/login' : '/register';

  const as = asPath ?? href.toString();
  const newHref = isAuthorized
    ? href
    : {
        pathname,
        query: { redirect_uri: as },
      };
  const newAs = isAuthorized
    ? as
    : {
        pathname,
        query: { redirect_uri: as },
      };

  return (
    <Link href={newHref} as={newAs} {...linkProps}>
      {children}
    </Link>
  );
};

export default AuthLink;
