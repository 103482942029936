import { FC } from 'react';
import styled, { useTheme } from 'styled-components';

const Star: FC<{ filled?: boolean }> = ({ filled }) => {
  const theme = useTheme();
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.3803 5.05583H5.29864L6.49241 1.53483C6.56705 1.31433 6.77147 1.16675 7 1.16675C7.22911 1.16675 7.43295 1.31433 7.50759 1.53483L8.70136 5.05583H12.6197C12.8988 5.05583 13.125 5.28567 13.125 5.56858C13.125 5.73541 13.0452 5.89116 12.9125 5.98683L9.78315 8.24608L11.1354 12.2349C11.2347 12.5283 11.0206 12.8334 10.7151 12.8334C10.6226 12.8334 10.5319 12.8048 10.4573 12.75L7 10.2551L3.54331 12.75C3.46809 12.8048 3.37794 12.8334 3.28492 12.8334C2.98002 12.8334 2.76527 12.5283 2.86461 12.2349L4.21685 8.24608L1.08745 5.98683C0.954814 5.89116 0.875 5.73541 0.875 5.56858C0.875 5.28567 1.10124 5.05583 1.3803 5.05583Z"
        fill={filled ? theme.colors.SafronCS2 : theme.colors.CG5}
      />
    </svg>
  );
};

const HalfStar: FC = () => {
  const theme = useTheme();
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.52625 1.53425L5.32485 5.03425H1.38566C1.16355 5.03322 0.966498 5.17795 0.899044 5.39168C0.831589 5.6054 0.909355 5.83861 1.09109 5.96758L4.23897 8.22508L2.88163 12.2501C2.8395 12.386 2.8637 12.5341 2.94685 12.6491C3.03 12.7641 3.16225 12.8325 3.30327 12.8334C3.39707 12.8338 3.4884 12.8031 3.56319 12.7459L6.99988 10.2842V1.16675C6.78667 1.19091 6.60429 1.33242 6.52625 1.53425Z"
        fill={theme.colors.SafronCS2}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.47375 1.53425L8.67515 5.03425H12.6143C12.8365 5.03322 13.0335 5.17795 13.101 5.39168C13.1684 5.6054 13.0906 5.83861 12.9089 5.96758L9.76103 8.22508L11.1184 12.2501C11.1605 12.386 11.1363 12.5341 11.0531 12.6491C10.97 12.7641 10.8378 12.8325 10.6967 12.8334C10.6029 12.8338 10.5116 12.8031 10.4368 12.7459L7.00012 10.2842V1.16675C7.21333 1.19091 7.39571 1.33242 7.47375 1.53425Z"
        fill={theme.colors.CG5}
      />
    </svg>
  );
};

const StyledStars = styled.div`
  margin-right: 8px;
  width: 90px;
  display: flex;

  svg {
    margin-left: 2px;

    &:first-child {
      margin-left: 0;
    }
  }
`;

const Stars: FC<{ rating: number }> = ({ rating }) => {
  const roundedRating = Math.round(2 * rating) / 2;

  return (
    <StyledStars>
      {roundedRating === 0.5 ? <HalfStar /> : <Star filled={rating >= 1} />}
      {roundedRating === 1.5 ? <HalfStar /> : <Star filled={rating >= 2} />}
      {roundedRating === 2.5 ? <HalfStar /> : <Star filled={rating >= 3} />}
      {roundedRating === 3.5 ? <HalfStar /> : <Star filled={rating >= 4} />}
      {roundedRating === 4.5 ? <HalfStar /> : <Star filled={rating >= 5} />}
    </StyledStars>
  );
};

export default Stars;
