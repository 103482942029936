import { AuthContext } from '@/common/context/AuthContext';
import { EventsContext } from '@/common/context/EventsContext';
import { TodoContext } from '@/common/context/TodoContext';
import {
  useFollowUserMutation,
  useUnfollowUserMutation,
} from '@/common/generated/graphql';
import { useRouter } from 'next/router';
import { ButtonHTMLAttributes, FC, MouseEvent, useContext } from 'react';

export type FollowEntryPoint =
  | 'Checklist'
  | 'Item_page'
  | 'Closet'
  | 'Search'
  | 'Home';

const ToggleFollowButton: FC<
  {
    userId: string;
    viewerIsFollowing: boolean;
    entryPoint: FollowEntryPoint;
  } & ButtonHTMLAttributes<HTMLButtonElement>
> = ({ userId, viewerIsFollowing, entryPoint, onClick, ...props }) => {
  const { isAuthorized } = useContext(AuthContext);
  const { handleTodoFetching } = useContext(TodoContext);
  const { logEvent } = useContext(EventsContext);
  const [followUser] = useFollowUserMutation({
    variables: {
      userId,
    },
    optimisticResponse: {
      __typename: 'Mutation',
      followUser: {
        __typename: 'FollowUserPayload',
        user: {
          __typename: 'User',
          id: userId,
          viewerIsFollowing: true,
        },
      },
    },
    errorPolicy: 'all',
  });
  const [unfollowUser] = useUnfollowUserMutation({
    variables: {
      userId,
    },
    optimisticResponse: {
      __typename: 'Mutation',
      unfollowUser: {
        __typename: 'UnfollowUserPayload',
        user: {
          __typename: 'User',
          id: userId,
          viewerIsFollowing: false,
        },
      },
    },
    errorPolicy: 'all',
  });

  const router = useRouter();

  const toggleFollow = async (event: MouseEvent<HTMLButtonElement>) => {
    if (isAuthorized) {
      if (viewerIsFollowing) {
        await unfollowUser();
        await handleTodoFetching();
      } else {
        await followUser();
        await handleTodoFetching();
        logEvent('follow', {
          entry_point: entryPoint,
          follow_id: userId,
        });
      }
    } else {
      router
        .push(`/register?redirect_uri=${encodeURIComponent(`user/${userId}`)}`)
        .then(() => {
          window.scrollTo(0, 0);
        });
    }

    if (onClick) {
      onClick(event);
    }
  };

  return <button onClick={toggleFollow} {...props} />;
};

export default ToggleFollowButton;
