import { AuthContext } from '@/common/context/AuthContext';
import { LanguageContext } from '@/common/context/LanguageContext';
import { useLogoutMutation } from '@/common/generated/graphql';
import { clearCookie, uwCookies } from '@/common/utils/cookies';
import XSmallSecondaryButton from '@/modules/design/components/buttons/XSmallSecondaryButton';
import LanguageMenu from '@/modules/menu/components/LanguageMenu';
import getConfig from 'next/config';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { transparentize } from 'polished';
import { FC, useContext } from 'react';
import styled from 'styled-components';
import urljoin from 'url-join';

const { publicRuntimeConfig } = getConfig();

const Header = styled.header`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  height: 52px;
  background-color: ${({ theme }) => theme.colors.CG7};
  z-index: 2;
  border-bottom: 1px solid
    ${({ theme }) => transparentize(0.92, theme.colors.CG1)};
  padding: 0 20px;
`;

const InnerHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 auto;
  align-items: center;
  max-width: 1120px;
`;

const LogoAnchor = styled.a`
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  outline: none;

  &:before {
    content: '';
    display: block;
    background-color: transparent;
    position: absolute;
    left: -8px;
    right: -8px;
    top: 0;
    bottom: 0;
    transition: background-color 0.2s ease-in;

    @media (max-width: ${({ theme }) => theme.breakpoints.tabletMaxWidth}) {
      width: 44px;
      height: 44px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border-radius: 50%;
      bottom: auto;
      right: auto;
    }
  }
`;

const LogoImage = styled.img.attrs({
  alt: 'Vinted',
  src: require('@/modules/assets/icons/vinted-logo-gray.svg'),
})`
  display: block;
  width: auto;
`;

const RightSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const SignInButton = styled(XSmallSecondaryButton)`
  margin-right: 24px;
`;

const ClosedTopMenu: FC = () => {
  const { translate, language } = useContext(LanguageContext);
  const { isAuthorized } = useContext(AuthContext);
  const [logoutMutate] = useLogoutMutation({ variables: { input: {} } });
  const router = useRouter();

  const logout = async () => {
    await logoutMutate();
    clearCookie(uwCookies.todo);
    window.location.href = urljoin(publicRuntimeConfig.BASE_URL, language);
  };

  return (
    <Header>
      <InnerHeader>
        <Link href={'/'} passHref>
          <LogoAnchor>
            <LogoImage />
          </LogoAnchor>
        </Link>
        <RightSection>
          {isAuthorized && router.pathname === '/settings' ? (
            <SignInButton onClick={logout}>
              {translate('login_logout')}
            </SignInButton>
          ) : (
            <Link href="/login" passHref>
              <SignInButton as="a">{translate('login_login')}</SignInButton>
            </Link>
          )}
          <LanguageMenu alignContextMenu={'bottom-right'} compact />
        </RightSection>
      </InnerHeader>
    </Header>
  );
};

export default ClosedTopMenu;
