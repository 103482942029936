import { FiltersContext } from '@/common/context/FiltersContext';
import { LanguageContext } from '@/common/context/LanguageContext';
import {
  FilterCategory,
  StaticDataContext,
} from '@/common/context/StaticDataContext';
import { transparentize } from 'polished';
import { FC, useContext } from 'react';
import styled from 'styled-components';
import { Heading5 } from '../../design/components/textstyles';
import { FilterLink } from '../../feed/components/ProductFilters/FilterLink';

const StyledProductsMenu = styled.ul`
  display: flex;
  flex-direction: row;
`;

const MenuItem = styled.li`
  display: block;
  position: relative;

  > ul {
    display: none;
  }

  &:hover {
    > ul {
      display: block;
    }
    > a {
      background-color: ${({ theme }) =>
        transparentize(0.98, theme.colors.CG1)};
      &:active {
        background-color: ${({ theme }) =>
          transparentize(0.92, theme.colors.CG1)};
      }
    }
  }
`;

interface MenuAnchorProps {
  isSelected?: boolean;
}

const MenuAnchor = styled(Heading5).attrs({ as: 'a' })<MenuAnchorProps>`
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  padding: 0 16px;
  font-size: 1.4rem;
  line-height: 1.8rem;
  color: ${({ theme }) => theme.colors.CG3};
  transition: background-color 0.2s ease-in, border-bottom 0.2s ease-in;
  border-bottom: 1px solid
    ${({ isSelected, theme }) =>
      isSelected ? theme.colors.CalypsoCP1 : 'transparent'};
`;

// const OutletAnchor = styled(MenuAnchor)`
//   color: ${({ theme }) => theme.colors.CoralCS3};
// `;

const SubMenu = styled.ul<{ label: string }>`
  li:hover > a {
    background-color: ${({ theme }) => transparentize(0.98, theme.colors.CG1)};

    &:active {
      background-color: ${({ theme }) =>
        transparentize(0.92, theme.colors.CG1)};
    }
  }

  &:before {
    position: relative;
    z-index: 1;
    content: ${({ label }) => `'${label}'`};
    display: block;
    font-size: 1.4rem;
    line-height: 1.8rem;
    color: ${({ theme }) => theme.colors.CG1};
    font-weight: ${({ theme }) => theme.fontWeights.medium};
    padding: 4px 20px;
  }
`;

const CategoryMenu = styled(SubMenu)`
  position: absolute;
  top: 43px;
  padding: 20px 0;

  li > ul > li {
    visibility: hidden;
  }

  li:hover > ul > li {
    visibility: visible;
  }

  li > ul {
    pointer-events: none;

    &:before {
      visibility: hidden;
    }
  }

  li:hover > ul {
    pointer-events: initial;

    &:before {
      visibility: visible;
    }
  }
`;

const SubcategoryMenu = styled(SubMenu)<{ showBackground: boolean }>`
  position: absolute;
  left: 0;
  top: 0;
  margin-left: 256px;
  padding: 20px 0; // 20px 256px;
  min-height: 100%;
  border-left: ${({ theme, showBackground }) =>
    `1px solid ${
      showBackground ? transparentize(0.92, theme.colors.CG1) : 'transparent'
    }`};

  &:after {
    content: '';
    position: absolute;
    display: ${({ showBackground }) => (showBackground ? 'block' : 'none')};
    left: -257px;
    right: -1px;
    top: -1px;
    bottom: -1px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    z-index: -1;
    background-color: ${({ theme }) => theme.colors.CG7};
    box-shadow: 0 1px 8px 0
      ${({ theme }) => transparentize(0.84, theme.colors.CG1)};
  }
`;

const SubMenuAnchor = styled(Heading5).attrs({ as: 'a' })`
  position: relative;
  z-index: 1;
  display: block;
  transition: background-color 0.2s ease-in;
  padding: 4px 20px;
  font-size: 1.4rem;
  line-height: 1.8rem;
  color: ${({ theme }) => theme.colors.CG3};
  width: 256px;
`;

const hasMostSubcategories = (
  index: number,
  subcategory: FilterCategory,
  category: FilterCategory
) =>
  category.children.some((child) => child.children)
    ? subcategory.id ===
      category.children
        .filter((child) => child.children)
        .reduce(
          (prev, cur) =>
            cur.children.length > prev.children.length ? cur : prev,
          category.children[0]
        ).id
    : index === 0;

const ProductsMenu: FC = () => {
  const { categories } = useContext(StaticDataContext);
  const { category: selectedCategory } = useContext(FiltersContext);
  const { translate } = useContext(LanguageContext);

  return (
    <StyledProductsMenu>
      {categories.map((category, index) => (
        <MenuItem key={index}>
          <FilterLink filters={{ categoryId: category.id }} passHref>
            <MenuAnchor
              isSelected={
                selectedCategory && selectedCategory.id === category.id
              }
            >
              {category.title}
            </MenuAnchor>
          </FilterLink>
          <CategoryMenu label={translate('gen_category')}>
            {category.children.map((subCategory, subCategoryIndex) => (
              <li key={subCategoryIndex}>
                <FilterLink
                  filters={{
                    categoryId: subCategory.id,
                  }}
                  passHref
                >
                  <SubMenuAnchor>{subCategory.title}</SubMenuAnchor>
                </FilterLink>
                <SubcategoryMenu
                  label={translate('gen_subcategory')}
                  showBackground={hasMostSubcategories(
                    index,
                    subCategory,
                    category
                  )}
                >
                  {subCategory.children?.map(
                    (subSubcategory, subSubCategoryIndex) => (
                      <li key={subSubCategoryIndex}>
                        <FilterLink
                          filters={{
                            categoryId: subSubcategory.id,
                          }}
                          passHref
                        >
                          <SubMenuAnchor>{subSubcategory.title}</SubMenuAnchor>
                        </FilterLink>
                      </li>
                    )
                  )}
                </SubcategoryMenu>
              </li>
            ))}
          </CategoryMenu>
        </MenuItem>
      ))}
      {/* <MenuItem>
        <Link href="/brands" as={`/${language}/brands`} passHref>
          <MenuAnchor>{translate('gen_brands')}</MenuAnchor>
        </Link>
      </MenuItem> */}
      {/* <MenuItem>
        <FilterLink
          filters={{
            onlyOutlet: true,
          }}
          passHref
        >
          <OutletAnchor>{translate('gen_outlet')}</OutletAnchor>
        </FilterLink>
      </MenuItem> */}
    </StyledProductsMenu>
  );
};

export default ProductsMenu;
