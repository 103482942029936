import { FiltersContext } from '@/common/context/FiltersContext';
import { LanguageContext } from '@/common/context/LanguageContext';
import { StaticDataContext } from '@/common/context/StaticDataContext';
import {
  SearchSuggestionsDocument,
  SearchSuggestionsQuery,
  SearchSuggestionsQueryVariables,
  UserSearchSuggestionFragment,
} from '@/common/generated/graphql';
import useDebounce from '@/common/hooks/useDebounce';
import useOutsideClick from '@/common/hooks/useOutsideClick';
import { buildAs, buildHref, buildPath } from '@/common/utils/filters';
import { useApolloClient } from '@apollo/client';
import { useRouter } from 'next/router';
import { transparentize } from 'polished';
import {
  FC,
  KeyboardEvent,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import styled from 'styled-components';
import { Heading5 } from '../../design/components/textstyles';
import { ProductSuggestion, UserSuggestion } from './SearchSuggestion';

const StyledSearchBar = styled.div`
  width: 100%;
  height: 36px;
  background-color: ${({ theme }) => theme.colors.CG6};
  border-radius: 4px;
  position: relative;
`;

const StyledInput = styled.input.attrs({ type: 'text' })`
  outline: none;
  width: 100%;
  height: 100%;
  line-height: 36px;
  padding: 0 12px 0 48px;
  border: none;
  font-size: 1.4rem;
  appearance: none;
  color: ${({ theme }) => theme.colors.CG1};
  font-family: ${({ theme }) => theme.fonts.sansSerif};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  background-color: transparent;
`;

const ResultsContainer = styled.div`
  position: absolute;
  top: 100%;
  margin-top: 8px;
  left: 0;
  right: 0;
  background-color: ${({ theme }) => theme.colors.CG7};
  border-radius: 2px;
  box-shadow: 0 1px 8px 0
    ${({ theme }) => transparentize(0.84, theme.colors.CG1)};
  z-index: 2;
  padding-bottom: 24px;

  &:after {
    display: block;
    content: '';
    position: absolute;
    right: 16px;
    top: -6px;
    border-bottom: 6px solid ${({ theme }) => theme.colors.CG7};
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
  }
`;

const ResultsTitle = styled(Heading5)`
  color: ${({ theme }) => theme.colors.CG3};
  padding: 24px 20px 8px 0;
  font-size: 1.4rem;
  line-height: 1.8rem;
  margin-left: 20px;
  margin-top: 20px;
  border-top: 1px solid ${({ theme }) => transparentize(0.92, theme.colors.CG1)};

  &:first-of-type {
    border-top: none;
    margin-top: 0;
  }
`;

const MagnifyingGlass = styled.img.attrs({
  src: require('@/modules/assets/icons/magnifying-glass-alt.svg'),
})`
  position: absolute;
  left: 16px;
  top: 50%;
  margin-top: -10px;
  width: 20px;
  height: 20px;
  pointer-events: none;
`;

const SearchBar: FC<{ className?: string }> = ({ className }) => {
  const client = useApolloClient();
  const router = useRouter();
  const { filters } = useContext(FiltersContext);
  const { findCategoryById } = useContext(StaticDataContext);
  const { language, translate } = useContext(LanguageContext);
  const inputRef = useRef<HTMLInputElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [query, setQuery] = useState(filters.query);
  const debouncedQuery = useDebounce(query, 250);
  const [suggestions, setSuggestions] = useState<SearchSuggestionsQuery>();
  const [selectedIndex, setSelectedIndex] = useState(-1);
  useOutsideClick(containerRef, () => setShowSuggestions(false));

  useEffect(() => {
    if (filters.query !== query) {
      inputRef.current.value = filters.query || '';
    }
  }, [filters.query]);

  useEffect(() => {
    (async () => {
      if (!showSuggestions) {
        return;
      }
      const { data } = await client.query<
        SearchSuggestionsQuery,
        SearchSuggestionsQueryVariables
      >({
        query: SearchSuggestionsDocument,
        variables: { query: debouncedQuery || '' },
      });

      if (data) {
        setSuggestions(data);
        setSelectedIndex(-1);
      }
    })();
  }, [debouncedQuery, showSuggestions]);

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    switch (e.keyCode) {
      // Return
      case 13:
        inputRef.current.blur();
        setSelectedIndex(-1);
        if (selectedIndex === -1) {
          applyQueryFilter(query);
        } else if (selectedIndex < suggestions.searchSuggestions.length) {
          applyQueryFilter(suggestions.searchSuggestions[selectedIndex].query);
        } else {
          goToCloset(
            suggestions.usersConnection.nodes[
              selectedIndex - suggestions.searchSuggestions.length
            ]
          );
        }
        break;
      // Escape
      case 27:
        inputRef.current.blur();
        setShowSuggestions(false);
        break;
      // Up
      case 38:
        e.preventDefault();
        if (suggestions) {
          setSelectedIndex(
            selectedIndex === -1
              ? suggestions.searchSuggestions.length +
                  suggestions.usersConnection.nodes.length -
                  1
              : selectedIndex - 1
          );
        }
        break;
      // Down
      case 40:
        e.preventDefault();
        if (suggestions) {
          setSelectedIndex(
            selectedIndex ===
              suggestions.searchSuggestions.length +
                suggestions.usersConnection.nodes.length -
                1
              ? -1
              : selectedIndex + 1
          );
        }
        break;
      // Tab
      case 9:
        e.preventDefault();
        break;
    }
  };

  const goToCloset = (user: UserSearchSuggestionFragment) => {
    setShowSuggestions(false);
    router.push(`/user/${user.id}`);
  };

  const applyQueryFilter = (newQuery: string) => {
    setShowSuggestions(false);
    const newFilters = { ...filters, query: newQuery };
    const path = buildPath(language, newFilters, findCategoryById);

    router.push(buildHref(newFilters), buildAs(newFilters, { path }), {
      shallow: true,
    });
  };

  return (
    <StyledSearchBar className={className} ref={containerRef}>
      <StyledInput
        onFocus={() => setShowSuggestions(true)}
        onChange={() => setQuery(inputRef.current.value)}
        onKeyDown={handleKeyDown}
        ref={inputRef}
        placeholder={translate('products_search_ph_3')}
        defaultValue={query}
      />
      {showSuggestions && suggestions ? (
        <ResultsContainer>
          <ResultsTitle>{translate('gen_products')}</ResultsTitle>
          {suggestions.searchSuggestions.length
            ? suggestions.searchSuggestions.map((suggestion, i) => (
                <ProductSuggestion
                  key={`${suggestion.type}:${suggestion.query}`}
                  suggestion={suggestion}
                  onClick={() => applyQueryFilter(suggestion.query)}
                  isSelected={i === selectedIndex}
                  tabIndex={i}
                  onMouseEnter={() => setSelectedIndex(i)}
                />
              ))
            : null}
          {suggestions.usersConnection.nodes.length ? (
            <>
              <ResultsTitle>{translate('gen_people')}</ResultsTitle>
              {suggestions.usersConnection.nodes.map((user, i) => (
                <UserSuggestion
                  key={user.id}
                  user={user}
                  onClick={() => goToCloset(user)}
                  isSelected={
                    i + suggestions.searchSuggestions.length === selectedIndex
                  }
                />
              ))}
            </>
          ) : null}
        </ResultsContainer>
      ) : null}
      <MagnifyingGlass alt={translate('gen_search')} />
    </StyledSearchBar>
  );
};

export default SearchBar;
