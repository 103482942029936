import { ClosetTab } from '@/modules/user/components/ClosetTabs';
import Link, { LinkProps } from 'next/link';
import { FC, PropsWithChildren } from 'react';

type Props = { userId: string; tab?: ClosetTab } & Omit<LinkProps, 'href'> &
  PropsWithChildren;

const UserLink: FC<Props> = ({
  userId,
  tab = ClosetTab.Products,
  ...props
}) => {
  return (
    <Link
      href={{
        pathname: `/user/${userId}`,
        query: { userId, tab },
      }}
      {...props}
    />
  );
};

export default UserLink;
