import ResponsiveContainer from '@/common/components/ResponsiveContainer';
import { LoaderWrapperHOC } from '@/common/components/Skeleton';
import { AuthContext } from '@/common/context/AuthContext';
import { ExperimentContext } from '@/common/context/ExperimentContext';
import { LanguageContext } from '@/common/context/LanguageContext';
import { useCartQuery, useViewerQuery } from '@/common/generated/graphql';
import useOutsideClick from '@/common/hooks/useOutsideClick';
import { getItemCount } from '@/common/utils/cart';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { transparentize } from 'polished';
import { FC, useContext, useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import AuthLink from '../../auth/components/AuthLink';
import XSmallPrimaryButton from '../../design/components/buttons/XSmallPrimaryButton';
import XSmallSecondaryButton from '../../design/components/buttons/XSmallSecondaryButton';
import { FilterLink } from '../../feed/components/ProductFilters/FilterLink';
import Cart from '../components/Cart';
import Chat from './Chat';
import LanguageMenu from './LanguageMenu';
import MenuIconButton from './MenuIconButton';
import MobileMenu from './MobileMenu';
import Notifications from './Notifications';
import ProductsMenu from './ProductsMenu';
import SearchBar from './SearchBar';
import TopMenuLoader from './SkeletonLoaders';
import TodoIcon from './TodoIcon';
import UserProfile from './UserProfile';

const StyledTopMenu = styled(ResponsiveContainer)`
  position: fixed;
  left: 0;
  right: 0;
  height: ${({ theme }) => theme.dimensions.appMenuBarHeight};
  background-color: ${({ theme }) => theme.colors.CG7};
  z-index: 2;
  border-bottom: 1px solid
    ${({ theme }) => transparentize(0.92, theme.colors.CG1)};
`;

const TopSection = styled.div`
  display: flex;
  flex-direction: row;
  height: 52px;
  max-width: ${({ theme }) => theme.breakpoints.smallDesktopMaxWidth};
  margin: 0 auto;
  align-items: center;
`;

const BottomSection = styled.nav`
  display: flex;
  flex-direction: row;
  max-width: ${({ theme }) => theme.breakpoints.smallDesktopMaxWidth};
  margin: 0 auto;
  height: 43px;

  @media (max-width: ${({ theme }) => theme.breakpoints.tabletMaxWidth}) {
    display: none;
  }
`;

const LogoAnchor = styled.a`
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  outline: none;

  &:before {
    content: '';
    display: block;
    background-color: transparent;
    position: absolute;
    left: -8px;
    right: -8px;
    top: 0;
    bottom: 0;
    transition: background-color 0.2s ease-in;

    @media (max-width: ${({ theme }) => theme.breakpoints.tabletMaxWidth}) {
      width: 44px;
      height: 44px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border-radius: 50%;
      bottom: auto;
      right: auto;
    }
  }
`;

const MenuIconWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  margin-right: 24px;
  height: 100%;

  @media (max-width: ${({ theme }) => theme.breakpoints.tabletMaxWidth}) {
    margin-right: 20px;
  }
`;

const LogoButton: FC = () => {
  const { isAuthorized } = useContext(AuthContext);
  return isAuthorized ? (
    <FilterLink filters={{}} passHref>
      <LogoAnchor>
        <LogoImage />
      </LogoAnchor>
    </FilterLink>
  ) : (
    <Link href={'/'} passHref>
      <LogoAnchor>
        <LogoImage />
      </LogoAnchor>
    </Link>
  );
};

const LogoImage = styled.img.attrs({
  alt: 'Vinted',
  src: require('@/modules/assets/icons/vinted-logo.svg'),
})`
  display: block;
  width: auto;
`;

const SignInButton = styled(XSmallSecondaryButton)`
  margin-right: 8px;

  @media (max-width: ${({ theme }) => theme.breakpoints.tabletMaxWidth}) {
    display: none;
  }

  &:hover {
    color: ${({ theme }) => theme.colors.CalypsoCP1};
  }
`;

const StyledSearchBar = styled(SearchBar)`
  @media (max-width: ${({ theme }) => theme.breakpoints.tabletMaxWidth}) {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 8px;
    z-index: 1;
  }
`;

const SearchContainer = styled.div`
  flex: 1;
  margin: 0 52px;
  @media (max-width: ${({ theme }) => theme.breakpoints.tabletMaxWidth}) {
    margin: 0;
  }
`;

const AddProductButton = styled(XSmallPrimaryButton).attrs({ as: 'a' })`
  @media (max-width: ${({ theme }) => theme.breakpoints.tabletMaxWidth}) {
    display: none;
  }
`;

const StyledUserProfile = styled(UserProfile)`
  margin-right: 24px;

  @media (max-width: ${({ theme }) => theme.breakpoints.tabletMaxWidth}) {
    margin-right: 0;
  }
`;

const StyledLanguageMenu = styled(LanguageMenu)`
  margin-left: 24px;

  @media (max-width: ${({ theme }) => theme.breakpoints.tabletMaxWidth}) {
    display: none;
  }
`;

const TopMenu: FC = () => {
  const { translate } = useContext(LanguageContext);
  const { isAuthorized } = useContext(AuthContext);
  const { data, loading } = useViewerQuery({
    errorPolicy: 'all',
    skip: !isAuthorized,
  });
  const { preComms, restriction } = useContext(ExperimentContext);
  const [isCartShown, setIsCartShown] = useState(false);
  const [isChatShown, setIsChatShown] = useState(false);
  const [areNotificationsShown, setAreNotificationsShown] = useState(false);
  const cartRef = useRef<HTMLDivElement>();
  const chatRef = useRef<HTMLDivElement>();
  const notificationsRef = useRef<HTMLDivElement>();
  const router = useRouter();

  const { data: cartData } = useCartQuery({
    fetchPolicy: 'cache-first',
    skip: !isAuthorized,
  });

  useOutsideClick(cartRef, () => setIsCartShown(false));
  useOutsideClick(chatRef, () => setIsChatShown(false));
  useOutsideClick(notificationsRef, () => setAreNotificationsShown(false));

  useEffect(() => {
    setIsCartShown(false);
  }, [router.route, router.query]);

  const toggleChat = () => {
    setIsChatShown(!isChatShown);
  };

  const toggleNotifications = () => {
    setAreNotificationsShown(!areNotificationsShown);
  };

  return (
    <StyledTopMenu innerStyle={innerStyle}>
      <TopSection>
        <LogoButton />
        <SearchContainer>
          <StyledSearchBar />
        </SearchContainer>
        <LoaderWrapperHOC
          isLoading={loading}
          LoaderComponent={<TopMenuLoader />}
        >
          {isAuthorized && data?.viewer?.me ? (
            <>
              <MenuIconWrapper ref={chatRef}>
                <MenuIconButton
                  icon={require('@/modules/assets/icons/chats.svg')}
                  onClick={toggleChat}
                />
                {isChatShown ? <Chat /> : null}
              </MenuIconWrapper>
              <MenuIconWrapper ref={notificationsRef}>
                <MenuIconButton
                  icon={require('@/modules/assets/icons/notifications.svg')}
                  onClick={toggleNotifications}
                />
                {areNotificationsShown ? <Notifications /> : null}
              </MenuIconWrapper>
              <TodoIcon userId={data.viewer.me?.id} />

              <MenuIconWrapper ref={cartRef}>
                <MenuIconButton
                  icon={require('@/modules/assets/icons/cart.svg')}
                  onClick={() => setIsCartShown(!isCartShown)}
                  unreadCount={
                    restriction.isInControlGroup &&
                    cartData &&
                    getItemCount(cartData.viewer.shoppingCart)
                  }
                />
                {isCartShown ? <Cart /> : null}
              </MenuIconWrapper>
              <StyledUserProfile />
              <AuthLink
                isAuthorized={isAuthorized}
                href="/add-product"
                passHref
              >
                <AddProductButton>{translate('gen_sell_now')}</AddProductButton>
              </AuthLink>
            </>
          ) : (
            <>
              <Link
                href={preComms.isInVariant ? '/login' : '/register'}
                passHref
              >
                <SignInButton as={'a'}>
                  {preComms.isInVariant
                    ? translate('login_login')
                    : translate('login_sign_up_login')}
                </SignInButton>
              </Link>
              <AuthLink
                isAuthorized={isAuthorized}
                href="/add-product"
                passHref
              >
                <AddProductButton>{translate('gen_sell_now')}</AddProductButton>
              </AuthLink>
              <StyledLanguageMenu
                compact={true}
                alignContextMenu="bottom-right"
              />
              <MobileMenu />
            </>
          )}
        </LoaderWrapperHOC>
      </TopSection>
      <BottomSection>
        <ProductsMenu />
      </BottomSection>
    </StyledTopMenu>
  );
};

export default TopMenu;

const innerStyle = css`
  position: relative;
`;
