import CartItems from '@/common/components/Cart/CartItems';
import { ExperimentContext } from '@/common/context/ExperimentContext';
import { LanguageContext } from '@/common/context/LanguageContext';
import { CartFragment, useCartQuery } from '@/common/generated/graphql';
import useFormatPrice from '@/common/hooks/useFormatPrice';
import ClosureNotice from '@/modules/closure/components/ClosureNotice';
import { ApolloError } from '@apollo/client';
import Link from 'next/link';
import { transparentize } from 'polished';
import { FC, forwardRef, useContext } from 'react';
import styled from 'styled-components';
import PrimaryButton from '../../design/components/buttons/PrimaryButton';
import Spinner from '../../design/components/spinner/Spinner';
import { Heading4, Heading5 } from '../../design/components/textstyles';
import { FilterLink } from '../../feed/components/ProductFilters/FilterLink';

const StyledCart = styled.section`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.CG7};
  width: 320px;
  max-height: calc(100vh - 52px);
  position: absolute;
  bottom: 0;
  left: 50%;
  box-shadow: 0 1px 8px 0
    ${({ theme }) => transparentize(0.84, theme.colors.CG1)};
  border-radius: 2px;
  transform: translate(-50%, 100%);

  &:after {
    display: block;
    content: '';
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: -6px;
    border-bottom: 6px solid ${({ theme }) => theme.colors.CG7};
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobileMaxWidth}) {
    position: fixed;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 3;
    top: 50px;
    transform: none;
    max-height: none;

    &:after {
      display: none;
    }
  }
`;

const CartSection = styled.div<{ fullWidth?: boolean }>`
  display: flex;
  flex-direction: column;
  padding-left: ${({ fullWidth }) => (fullWidth ? '20px' : 0)};
  padding-right: 20px;
  padding-bottom: 24px;
  margin: ${({ fullWidth }) => (fullWidth ? '24px 0 0 0' : '24px 0 0 20px')};
  border-bottom: 1px solid
    ${({ theme }) => transparentize(0.92, theme.colors.CG1)};
  overflow: hidden;

  &:last-of-type {
    border-bottom: none;
  }
`;

const CartItemsContainer = styled.div`
  flex: 1;
  overflow: auto;
`;

const TotalContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 24px;
`;

const CheckoutButton = styled(PrimaryButton).attrs({ as: 'a' })`
  width: 100%;
  max-width: none;
`;

const CenteredContainer = styled.div`
  height: 360px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const EmptyCartImage = styled.img.attrs({
  src: require('@/modules/assets/icons/empty-cart.svg'),
})`
  height: 80px;
  width: auto;
`;

const EmptyCartTitle = styled(Heading5)`
  margin-top: 24px;
  text-align: center;
`;

const EmptyCartExpl = styled(Heading5).attrs({ as: 'span' })`
  color: ${({ theme }) => theme.colors.CG3};
  text-align: center;
  margin-top: 8px;
  margin-bottom: 40px;
`;

const Cart = forwardRef<HTMLElement, { className?: string }>(
  ({ className }, ref) => {
    const { restriction } = useContext(ExperimentContext);

    return (
      <StyledCart ref={ref} className={className}>
        {restriction.isInVariant ? <ClosureNotice /> : <InnerCart />}
      </StyledCart>
    );
  }
);
Cart.displayName = 'Cart';

const InnerCart: FC = () => {
  const { data, loading, error } = useCartQuery({
    fetchPolicy: 'cache-first',
  });

  if (loading) {
    return (
      <CenteredContainer>
        <Spinner wait={100} />
      </CenteredContainer>
    );
  }

  if (error || !data) {
    return <Error error={error} />;
  }

  return <CartContent cart={data.viewer.shoppingCart} />;
};

const Error: FC<{ error?: ApolloError }> = ({ error }) => {
  return <span>{error && error.message ? error.message : 'no data'}</span>;
};

const CartContent: FC<{ cart: CartFragment }> = ({ cart }) => {
  const { translate, locale } = useContext(LanguageContext);
  const formatPrice = useFormatPrice();

  if (cart.groups.length === 0) {
    return (
      <CenteredContainer>
        <EmptyCartImage />
        <EmptyCartTitle>{translate('cart_empty')}</EmptyCartTitle>
        <EmptyCartExpl>{translate('cart_empty_search')}</EmptyCartExpl>
        <FilterLink filters={{}} passHref>
          <PrimaryButton as={'a'}>
            {translate('message_placeholder_button')}
          </PrimaryButton>
        </FilterLink>
      </CenteredContainer>
    );
  }

  return (
    <>
      <CartSection>
        <Heading5>{translate('cart_cart')}</Heading5>
        <CartItemsContainer>
          <CartItems canRemoveFromCart={true} cart={cart} />
        </CartItemsContainer>
      </CartSection>
      <CartSection fullWidth={true}>
        <TotalContainer>
          <Heading4>{translate('cart_subtotal')}</Heading4>
          <Heading4>
            {formatPrice(cart.prices.subtotal.amount, locale)}
          </Heading4>
        </TotalContainer>
        <Link href={'/checkout'} passHref>
          <CheckoutButton>{translate('checkout_checkout')}</CheckoutButton>
        </Link>
      </CartSection>
    </>
  );
};

export default Cart;
