import appIcon from '@/modules/assets/icons/app-icon.png';
import SmallPrimaryButton from '@/modules/design/components/buttons/SmallPrimaryButton';
import SmallSecondaryButton from '@/modules/design/components/buttons/SmallSecondaryButton';
import { Callout } from '@/modules/design/components/textstyles';
import MobileDetect from 'mobile-detect';
import Image from 'next/image';
import { transparentize } from 'polished';
import { FC, useCallback, useContext } from 'react';
import styled, { css } from 'styled-components';
import { EventsContext } from '../context/EventsContext';
import { ExperimentContext } from '../context/ExperimentContext';
import { LanguageContext } from '../context/LanguageContext';
import { MobileOverlayContext } from '../context/MobileOverlayContext';
import { getAppStoreUrl, getPlayStoreUrl } from '../utils/externalUrls';
import Stars from './Ratings/Stars';

const StyledMobileOverlay = styled.div<{ isFullHeight?: boolean }>`
  border-top: 1px solid ${({ theme }) => theme.colors.CG5};
  position: fixed;
  bottom: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  z-index: 999;

  ${({ isFullHeight }) =>
    isFullHeight
      ? css`
          top: 0;
        `
      : null};
`;

const Footer = styled.div`
  background-color: ${({ theme }) => theme.colors.CG7};
  padding: 16px;
  width: 100%;
`;

const LabelsContainer = styled.div`
  margin-left: 8px;
`;

const AppRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const AppTitle = styled(Callout)`
  color: ${({ theme }) => theme.colors.CG1};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  margin-right: 4px;
`;

const AppSubtitle = styled(Callout)`
  margin-top: 2px;
  color: ${({ theme }) => transparentize(0.4, theme.colors.CG1)};
`;

const ButtonRow = styled.div`
  margin-top: 16px;
  display: block;

  button {
    margin-right: 12px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobileMaxWidth}) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 12px;

    button {
      margin-right: 0;
    }
  }
`;

const AppTitleRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const MobileOverlay: FC = () => {
  const { logEvent } = useContext(EventsContext);
  const { translate } = useContext(LanguageContext);
  const { hideMobileOverlayVisible, isMobileOverlayVisible } =
    useContext(MobileOverlayContext);
  const { preComms } = useContext(ExperimentContext);

  if (preComms.isInVariant) {
    return null;
  }

  const handleGetAppClick = useCallback(async () => {
    const { userAgent } = window.navigator;
    const mobileDetect = new MobileDetect(userAgent);
    const url =
      mobileDetect.os() === 'AndroidOS' ? getPlayStoreUrl() : getAppStoreUrl();

    await logEvent('guest_app_download');

    if (mobileDetect.os() === 'iOS') {
      window.location.assign(url);
    } else {
      window.open(url, '_blank');
    }
  }, []);

  const handleContinueClick = useCallback(() => {
    logEvent('guest_continue_website');
    hideMobileOverlayVisible();
  }, [hideMobileOverlayVisible]);

  if (!isMobileOverlayVisible) {
    return null;
  }

  return (
    <StyledMobileOverlay>
      <Footer>
        <AppRow>
          <Image src={appIcon} width={42} height={42} />
          <LabelsContainer>
            <AppTitleRow>
              <AppTitle>{translate('download_app_title')}</AppTitle>
              <Stars rating={4.5} />
            </AppTitleRow>
            <AppSubtitle>{translate('download_app_expl')}</AppSubtitle>
          </LabelsContainer>
        </AppRow>
        <ButtonRow>
          <SmallSecondaryButton onClick={handleContinueClick}>
            {translate('gen_close')}
          </SmallSecondaryButton>
          <SmallPrimaryButton onClick={handleGetAppClick}>
            {translate('download_app_get_app')}
          </SmallPrimaryButton>
        </ButtonRow>
      </Footer>
    </StyledMobileOverlay>
  );
};

export default MobileOverlay;
