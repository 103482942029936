import { FC } from 'react';
import styled from 'styled-components';

const StyledUnreadCount = styled.span<{ isSmall: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${({ isSmall }) => (isSmall ? '1.1rem' : '1.4rem')};
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.CoralCS3};
  color: ${({ theme }) => theme.colors.CG7};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  font-family: ${({ theme }) => theme.fonts.sansSerif};
  text-align: center;
`;

const UnreadCount: FC<{ count: number; className?: string }> = ({
  count,
  className,
}) => {
  return count ? (
    <StyledUnreadCount className={className} isSmall={count > 9}>
      {count > 9 ? '9+' : count}
    </StyledUnreadCount>
  ) : null;
};

export default UnreadCount;
