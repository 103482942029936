import UnreadCount from '@/common/components/UnreadCount';
import { transparentize } from 'polished';
import { FC, PropsWithChildren } from 'react';
import styled from 'styled-components';

const StyledMenuIconButton = styled.button`
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  border: none;
  outline: none;
  appearance: none;
  background-color: transparent;
  padding: 0;
  cursor: pointer;

  &:before {
    content: '';
    display: block;
    background-color: transparent;
    position: absolute;
    left: -8px;
    right: -8px;
    top: 6px;
    bottom: 6px;
    transition: background-color 0.2s ease-in;
    border-radius: 4px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.tabletMaxWidth}) {
    &:before {
      left: -10px;
      right: -10px;
    }
  }

  &:hover:before {
    background-color: ${({ theme }) => transparentize(0.98, theme.colors.CG1)};
  }

  &:active:before {
    background-color: ${({ theme }) => transparentize(0.92, theme.colors.CG1)};
  }
`;

const StyledUnreadCount = styled(UnreadCount)`
  position: absolute;
  bottom: 50%;
  left: 50%;
  transform: translateY(-4px, -4px);
  z-index: 2;
`;

const MenuIconImage = styled.img`
  height: 24px;
  width: 24px;
  position: relative;
  z-index: 1;
`;

type Props = {
  unreadCount?: number;
  icon: string;
  className?: string;
  onClick?: () => void;
} & PropsWithChildren;

const MenuIconButton: FC<Props> = ({
  unreadCount,
  icon,
  className,
  onClick,
  children,
}) => (
  <StyledMenuIconButton onClick={onClick} className={className}>
    <StyledUnreadCount count={unreadCount} />
    <MenuIconImage src={icon} />
    {children}
  </StyledMenuIconButton>
);

export default MenuIconButton;
