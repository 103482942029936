import Spinner from '@/modules/design/components/spinner/Spinner';
import { linearGradient } from 'polished';
import { FC, PropsWithChildren, ReactNode } from 'react';
import styled, { keyframes } from 'styled-components';

const skeletonKeyframes = keyframes`
  0% {
    background-position: -200px 0;
  }
  100% {
    background-position: calc(200px + 100%) 0;
  }
`;

export const Skeleton = styled.div`
  display: inline-block;
  animation: ${skeletonKeyframes} 1300ms ease-in-out infinite;
  background-size: 200px 100%;
  background-repeat: no-repeat;
  border-radius: 4px;
  ${({ theme }) =>
    linearGradient({
      colorStops: [theme.colors.CG6, theme.colors.CG6, theme.colors.CG6],
      fallback: theme.colors.CG6,
      toDirection: 'to right',
    })}
`;

const StyledSpinner = styled(Spinner)`
  position: absolute;
  top: 40%;
  z-index: 1;
`;

const StyledSpinnerContainer = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.colors.CG5};
  z-index: 1;
  opacity: 0.5;
`;

// How to: always use this loader overlay with "position: relative" parent
export const LoaderOverlay: FC<{ isLoading?: boolean }> = ({ isLoading }) => {
  return (
    <>
      {isLoading && (
        <>
          <StyledSpinnerContainer />
          <StyledSpinner />
        </>
      )}
    </>
  );
};

type Props = {
  isLoading: boolean;
  LoaderComponent: ReactNode;
} & PropsWithChildren;

export const LoaderWrapperHOC: FC<Props> = ({
  children,
  isLoading,
  LoaderComponent,
}) => {
  if (isLoading) return <>{LoaderComponent}</>;
  return <>{children}</>;
};
