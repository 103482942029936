import { LanguageContext } from '@/common/context/LanguageContext';
import {
  SearchSuggestion,
  SearchSuggestionType,
  UserSearchSuggestionFragment,
} from '@/common/generated/graphql';
import FollowButton from '@/modules/design/components/buttons/FollowButton';
import { transparentize } from 'polished';
import { FC, HTMLAttributes, useContext, useState } from 'react';
import styled from 'styled-components';
import { Caption2, Heading5 } from '../../design/components/textstyles';

type SuggestionProps = HTMLAttributes<HTMLDivElement> & { isSelected: boolean };

const ProfileImage = styled.img`
  height: 32px;
  width: 32px;
  border-radius: 50%;
`;

const Labels = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 0 12px;
`;

const StyledSuggestion = styled.div<{ isSelected: boolean }>`
  display: flex;
  height: 56px;
  flex-direction: row;
  align-items: center;
  padding: 0 20px;
  transition: background-color 0.2s ease-in;
  cursor: pointer;
  outline: none;
  background-color: ${({ theme, isSelected }) =>
    isSelected ? transparentize(0.98, theme.colors.CG1) : 'transparent'};

  &:hover {
    background-color: ${({ theme }) => transparentize(0.98, theme.colors.CG1)};
  }

  @media (max-width: ${({ theme: { breakpoints } }) =>
      breakpoints.mobileMaxWidth}) {
    &:hover {
      background-color: transparent;
    }
  }

  &:active {
    background-color: ${({ theme }) => transparentize(0.92, theme.colors.CG1)};
  }
`;

export const ProductSuggestion: FC<
  { suggestion: SearchSuggestion } & SuggestionProps
> = ({ suggestion, ...props }) => {
  const { translate } = useContext(LanguageContext);
  return (
    <StyledSuggestion {...props}>
      <ProfileImage
        src={
          suggestion.type === SearchSuggestionType.Recent
            ? require('@/modules/assets/icons/recent-search.svg')
            : require('@/modules/assets/icons/suggested-search.svg')
        }
      />
      <Labels>
        <Heading5>{suggestion.label}</Heading5>
        {suggestion.resultCount ? (
          <Caption2>
            {translate('products_n_results', [suggestion.resultCount])}
          </Caption2>
        ) : null}
      </Labels>
    </StyledSuggestion>
  );
};

export const UserSuggestion: FC<
  { user: UserSearchSuggestionFragment } & SuggestionProps
> = ({ user, ...props }) => {
  const { translate } = useContext(LanguageContext);
  const [viewerIsFollowing, setViewerIsFollowing] = useState(
    user.viewerIsFollowing
  );
  return (
    <StyledSuggestion {...props}>
      <ProfileImage alt={user.displayName} src={user.profileImage.url} />
      <Labels>
        <Heading5>{user.displayName}</Heading5>
        <Caption2>
          {translate('gen_n_followers', [user.followerCount])}
        </Caption2>
      </Labels>
      <FollowButton
        isSmall={true}
        userId={user.id}
        viewerIsFollowing={viewerIsFollowing}
        onClick={(e) => {
          e.stopPropagation();
          setViewerIsFollowing((status) => !status);
        }}
        entryPoint={'Search'}
      >
        {translate(viewerIsFollowing ? 'gen_following' : 'gen_follow')}
      </FollowButton>
    </StyledSuggestion>
  );
};
