import UserLink from '@/common/components/UserLink';
import { LanguageContext } from '@/common/context/LanguageContext';
import { formatNumber } from '@/common/utils/number';
import { FC, forwardRef, useContext } from 'react';
import styled from 'styled-components';

export enum ClosetTab {
  Products = 'closet',
  Favorites = 'favorites',
  Followers = 'followers',
  Following = 'following',
}

const StyledTab = styled.a<{ selected?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 120px;
  color: ${({ selected, theme }) =>
    selected ? theme.colors.CG1 : theme.colors.CG3};
`;

const Count = styled.span`
  margin-bottom: 8px;
  font-size: 3rem;

  font-weight: ${({ theme }) => theme.fontWeights.medium};

  @media (max-width: ${({ theme: { breakpoints } }) =>
      breakpoints.mobileMaxWidth}) {
    font-size: 2.4rem;
  }
`;

const Title = styled.span<{ selected?: boolean }>`
  margin-bottom: 8px;
  font-size: 1.6rem;

  font-weight: ${({ selected }) => (selected ? 500 : 300)};
`;

const Indicator = styled.div`
  background-color: ${({ theme }) => theme.colors.CalypsoCP1};
  height: 4px;
  width: 36px;
  border-radius: 2px;
`;

const Tab: FC<{
  count: string;
  title: string;
  selected?: boolean;
}> = forwardRef(({ count, title, selected, ...props }, ref) => {
  return (
    <StyledTab {...props} selected={selected} ref={ref as any}>
      <Count>{count}</Count>
      <Title selected={selected}>{title}</Title>
      {selected ? <Indicator /> : null}
    </StyledTab>
  );
});

Tab.displayName = 'Tab';

const StyledClosetTabs = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ClosetTabs: FC<{
  userId: string;
  productCount: number;
  favoriteCount: number;
  followerCount: number;
  followingCount: number;
  selectedTab: ClosetTab;
}> = ({
  userId,
  productCount,
  favoriteCount,
  followerCount,
  followingCount,
  selectedTab,
  ...props
}) => {
  const { translate, locale } = useContext(LanguageContext);
  return (
    <StyledClosetTabs {...props}>
      <UserLink
        userId={userId}
        tab={ClosetTab.Products}
        scroll={false}
        passHref
      >
        <Tab
          count={formatNumber(productCount, { locale, abbreviate: true })}
          title={translate('gen_products')}
          selected={selectedTab === ClosetTab.Products}
        />
      </UserLink>
      <UserLink
        userId={userId}
        tab={ClosetTab.Favorites}
        scroll={false}
        passHref
      >
        <Tab
          count={formatNumber(favoriteCount, { locale, abbreviate: true })}
          title={translate('gen_favorites')}
          selected={selectedTab === ClosetTab.Favorites}
        />
      </UserLink>
      <UserLink
        userId={userId}
        tab={ClosetTab.Followers}
        scroll={false}
        passHref
      >
        <Tab
          count={formatNumber(followerCount, { locale, abbreviate: true })}
          title={translate('gen_followers')}
          selected={selectedTab === ClosetTab.Followers}
        />
      </UserLink>
      <UserLink
        userId={userId}
        tab={ClosetTab.Following}
        scroll={false}
        passHref
      >
        <Tab
          count={formatNumber(followingCount, { locale, abbreviate: true })}
          title={translate('gen_following')}
          selected={selectedTab === ClosetTab.Following}
        />
      </UserLink>
    </StyledClosetTabs>
  );
};

export default ClosetTabs;
