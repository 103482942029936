import UserLink from '@/common/components/UserLink';
import { AuthContext } from '@/common/context/AuthContext';
import { LanguageContext } from '@/common/context/LanguageContext';
import {
  LogoutDocument,
  LogoutMutation,
  LogoutMutationVariables,
  useViewerQuery,
} from '@/common/generated/graphql';
import useOutsideClick from '@/common/hooks/useOutsideClick';
import { clearCookie, uwCookies } from '@/common/utils/cookies';
import { getHelpCenterUrl } from '@/common/utils/externalUrls';
import { useApolloClient } from '@apollo/client';
import getConfig from 'next/config';
import Link from 'next/link';
import { transparentize } from 'polished';
import { FC, useContext, useRef, useState } from 'react';
import styled from 'styled-components';
import urljoin from 'url-join';
import PrimaryButton from '../../design/components/buttons/PrimaryButton';
import { Callout, Heading5 } from '../../design/components/textstyles';

const { publicRuntimeConfig } = getConfig();

const StyledUserProfile = styled.div`
  position: relative;
  height: 100%;
`;

const UserProfileButton = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;

  &:before {
    content: '';
    display: block;
    background-color: transparent;
    position: absolute;
    left: -8px;
    right: -8px;
    top: 6px;
    bottom: 6px;
    transition: background-color 0.2s ease-in;
    border-radius: 4px;
  }

  &:hover:before {
    background-color: ${({ theme }) => transparentize(0.98, theme.colors.CG1)};
  }

  &:active:before {
    background-color: ${({ theme }) => transparentize(0.92, theme.colors.CG1)};
  }
`;

const ProfileImage = styled.img`
  width: 28px;
  height: 28px;
  border-radius: 50%;
`;

const ExpandIcon = styled.img.attrs({
  src: require('@/modules/assets/icons/angle-down.svg'),
})`
  @media (max-width: ${({ theme }) => theme.breakpoints.tabletMaxWidth}) {
    display: none;
  }
`;

const DisplayName = styled(Callout)`
  margin-left: 8px;
  max-width: 64px;
  text-overflow: ellipsis;
  overflow: hidden;

  @media (max-width: ${({ theme }) => theme.breakpoints.tabletMaxWidth}) {
    display: none;
  }
`;

const ContextMenu = styled.div`
  position: absolute;
  top: 100%;
  width: 260px;
  right: 0;
  background-color: ${({ theme }) => theme.colors.CG7};
  border-radius: 2px;
  box-shadow: 0 1px 8px 0
    ${({ theme }) => transparentize(0.84, theme.colors.CG1)};
  z-index: 2;
  padding-bottom: 16px;

  &:after {
    display: block;
    content: '';
    position: absolute;
    right: 6px;
    top: -6px;
    border-bottom: 6px solid ${({ theme }) => theme.colors.CG7};
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
  }
`;

const ContextMenuHeading = styled(Heading5)`
  border-top: 1px solid ${({ theme }) => transparentize(0.92, theme.colors.CG1)};
  margin-bottom: 4px;
  padding: 16px 20px 0 0;
  margin: 16px 0 4px 20px;

  @media (min-width: ${({ theme }) => theme.breakpoints.tabletMaxWidth}) {
    &:first-of-type {
      border-top: none;
      margin-top: 8px;
    }
  }
`;

const ContextMenuAnchor = styled(Heading5).attrs({ as: 'a' })`
  color: ${({ theme }) => theme.colors.CG3};
  padding: 4px 20px;
  display: flex;
  justify-content: space-between;

  &:hover {
    background-color: ${({ theme }) => transparentize(0.98, theme.colors.CG1)};
  }

  &:active {
    background-color: ${({ theme }) => transparentize(0.92, theme.colors.CG1)};
  }
`;

const LogoutButton = styled(ContextMenuAnchor).attrs({ as: 'span' })`
  color: ${({ theme }) => theme.colors.CoralCS3};
  cursor: pointer;
`;

const AddProductButton = styled(PrimaryButton).attrs({ as: 'a' })`
  display: none;
  @media (max-width: ${({ theme }) => theme.breakpoints.tabletMaxWidth}) {
    display: flex;
    margin: 24px 20px;
    min-width: 0;
  }
`;

const UserProfile: FC<{ className?: string }> = ({ className }) => {
  const containerRef = useRef<HTMLDivElement>();
  const [isContextMenuOpened, setIsContextMenuOpened] = useState(false);
  const { language, translate } = useContext(LanguageContext);
  const { isAuthorized } = useContext(AuthContext);
  const { data } = useViewerQuery({ skip: !isAuthorized, errorPolicy: 'all' });
  const apolloClient = useApolloClient();
  useOutsideClick(containerRef, () => setIsContextMenuOpened(false));

  const logout = async () => {
    await apolloClient.mutate<LogoutMutation, LogoutMutationVariables>({
      mutation: LogoutDocument,
      variables: { input: {} },
    });
    clearCookie(uwCookies.todo);
    window.location.href = urljoin(publicRuntimeConfig.BASE_URL, language);
  };

  if (!data || !data.viewer) {
    return null;
  }

  return (
    <StyledUserProfile className={className} ref={containerRef}>
      <UserProfileButton onClick={() => setIsContextMenuOpened(true)}>
        <ProfileImage src={data.viewer.me?.profileImage?.url} />
        <DisplayName>{data.viewer.me?.displayName}</DisplayName>
        <ExpandIcon />
      </UserProfileButton>
      {isContextMenuOpened ? (
        <ContextMenu>
          <Link href={'/add-product'} passHref>
            <AddProductButton>{translate('gen_sell_now')}</AddProductButton>
          </Link>
          <ContextMenuHeading>
            {translate('gen_your_account')}
          </ContextMenuHeading>
          <ul>
            <li>
              <UserLink userId={data.viewer.me?.id} passHref>
                <ContextMenuAnchor>
                  {translate('closet_go_to_profile')}
                </ContextMenuAnchor>
              </UserLink>
            </li>
            <li>
              <Link href={'/orders'} passHref>
                <ContextMenuAnchor>
                  {translate('orders_my_orders')}
                </ContextMenuAnchor>
              </Link>
            </li>
            <li>
              <Link href={'/bids'} passHref>
                <ContextMenuAnchor>
                  {translate('bids_my_bids')}
                </ContextMenuAnchor>
              </Link>
            </li>
          </ul>
          <ContextMenuHeading>{translate('gen_other')}</ContextMenuHeading>
          <ul>
            <li>
              <Link href={'/'} passHref>
                <ContextMenuAnchor>{translate('home_home')}</ContextMenuAnchor>
              </Link>
            </li>
            <li>
              <Link href={'/settings'} passHref>
                <ContextMenuAnchor>
                  {translate('settings_settings')}
                </ContextMenuAnchor>
              </Link>
            </li>
            <li>
              <ContextMenuAnchor
                as="a"
                href={getHelpCenterUrl(language)}
                target="_blank"
                rel="nofollow"
              >
                {translate('help_center')}
              </ContextMenuAnchor>
            </li>
            <li>
              <LogoutButton onClick={logout}>
                {translate('login_logout')}
              </LogoutButton>
            </li>
          </ul>
        </ContextMenu>
      ) : null}
    </StyledUserProfile>
  );
};

export default UserProfile;
