import { Skeleton } from '@/common/components/Skeleton';
import { FC } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const IconSkeleton = styled(Skeleton)`
  width: 24px;
  height: 24px;
  margin-right: 20px;
`;

const ProfPicSkeleton = styled(Skeleton)`
  width: 30px;
  height: 30px;
  border-radius: 100%;
`;

const CTASkeleton = styled(Skeleton)`
  width: 63px;
  height: 28px;

  @media (max-width: ${({ theme }) => theme.breakpoints.tabletMaxWidth}) {
    display: none;
  }
`;

const ProfileNameSkeleton = styled(Skeleton)`
  width: 80px;
  height: 8px;
  margin: 0 24px 0px 6px;
  @media (max-width: ${({ theme }) => theme.breakpoints.tabletMaxWidth}) {
    display: none;
  }
`;

const TopMenuLoader: FC = () => {
  return (
    <Container>
      <IconSkeleton />
      <IconSkeleton />
      <IconSkeleton />
      <IconSkeleton />
      <ProfPicSkeleton />
      <ProfileNameSkeleton />
      <CTASkeleton />
    </Container>
  );
};

export default TopMenuLoader;
