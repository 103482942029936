import { useEffect, useState } from 'react';

export default function useInAppBrowser() {
  const [isInAppBrowser, setIsInAppBrowser] = useState(false);
  useEffect(() => {
    if (navigator.userAgent.indexOf('vinted-ca-in-app') !== -1) {
      setIsInAppBrowser(true);
    }
  });
  return isInAppBrowser;
}
