import dayjs, { Dayjs } from 'dayjs';
import { FC, useContext, useEffect, useState } from 'react';
import { LanguageContext } from '../context/LanguageContext';

const TimeAgo: FC<{ date: Dayjs; liveUpdate?: boolean }> = ({
  date,
  liveUpdate,
}) => {
  const [hasMounted, setHasMounted] = useState(false);
  useEffect(() => {
    setHasMounted(true);
  }, []);
  const { translate } = useContext(LanguageContext);
  const [now, setNow] = useState(dayjs());
  const seconds = dayjs.duration(now.diff(date)).asSeconds();
  let interval = Math.round(seconds / 31536000);

  useEffect(() => {
    if (liveUpdate) {
      const clock = setInterval(() => {
        setNow(dayjs());
      }, 1000);
      return () => {
        clearInterval(clock);
      };
    }
  }, [liveUpdate]);

  const getTimeAgo = () => {
    if (interval === 1) {
      return translate('gen_year_ago', interval);
    }
    if (interval > 1) {
      return translate('gen_years_ago_p', interval);
    }

    interval = Math.round(seconds / 2592000);
    if (interval === 1) {
      return translate('gen_month_ago', interval);
    }
    if (interval > 1) {
      return translate('gen_months_ago_p', interval);
    }

    interval = Math.round(seconds / 86400);
    if (interval === 1) {
      return translate('gen_day_ago', interval);
    }
    if (interval > 1) {
      return translate('gen_days_ago_p', interval);
    }

    interval = Math.round(seconds / 3600);
    if (interval === 1) {
      return translate('gen_hour_ago', interval);
    }
    if (interval > 1) {
      return translate('gen_hours_ago_p', interval);
    }

    interval = Math.round(seconds / 60);
    if (interval === 1) {
      return translate('gen_minute_ago', interval);
    }
    if (interval > 1) {
      return translate('gen_minutes_ago_p', interval);
    }

    return translate('gen_just_now');
  };
  if (!hasMounted) {
    return null;
  }
  return <>{getTimeAgo()}</>;
};

export default TimeAgo;
