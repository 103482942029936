import { transparentize } from 'polished';
import {
  ButtonHTMLAttributes,
  ComponentProps,
  FC,
  PropsWithChildren,
} from 'react';
import styled, { css } from 'styled-components';
import ToggleFollowButton from './ToggleFollowButton';

type FollowButtonProps = ComponentProps<typeof ToggleFollowButton> &
  ButtonHTMLAttributes<HTMLButtonElement> &
  PropsWithChildren;

const StyledFollowButton = styled(ToggleFollowButton)<FollowButtonProps>`
  display: inline-flex;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colors.CalypsoCP1};
  align-items: center;
  justify-content: center;
  
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  color: ${({ theme }) => theme.colors.CalypsoCP1};
  background-color: transparent;
  cursor: pointer;

  height: 32px;
  padding: 9px 11px;
  font-size: 1.4rem;


  &:hover {
    background-color: ${({ theme }) =>
      transparentize(0.96, theme.colors.CalypsoCP1)};
  }

  @media (max-width: ${({ theme: { breakpoints } }) =>
    breakpoints.mobileMaxWidth}) {
      height: 28px;
            font-size: 1.2rem;
        &:hover {
          background-color: transparent;
        }
  }

  ${({ viewerIsFollowing }) =>
    viewerIsFollowing
      ? css`
          background-color: ${({ theme }) => theme.colors.CalypsoCP1};
          color: ${({ theme }) => theme.colors.CG7};
          border: none;

          &:hover {
            background-color: ${({ theme }) =>
              transparentize(0.8, theme.colors.CG1)};
            color: ${({ theme }) => theme.colors.CG7};
          }

          @media (max-width: ${({ theme: { breakpoints } }) =>
              breakpoints.mobileMaxWidth}) {
            &:hover {
              background-color: ${({ theme }) => theme.colors.CalypsoCP1};
              color: ${({ theme }) => theme.colors.CG7};
              border: none;
            }
          }
        `
      : null}}
`;

const SmallStyledFollowButton = styled(StyledFollowButton)`
  height: 28px;
  padding: 0 8px;
  font-size: 1.2rem;
`;

const CheckedIcon = styled.img.attrs({
  src: require('@/modules/assets/icons/check.svg'),
})<{ isSmall: boolean }>`
  margin-right: ${({ isSmall }) => (isSmall ? 5.33 : 10)}px;
  margin-left: ${({ isSmall }) => (isSmall ? 1.33 : 2)}px;
  height: 10px;
  width: 13px;
`;

const FollowButton: FC<FollowButtonProps & { isSmall?: boolean }> = ({
  viewerIsFollowing,
  isSmall,
  children,
  onClick,
  ...props
}) => {
  if (isSmall) {
    return (
      <SmallStyledFollowButton
        viewerIsFollowing={viewerIsFollowing}
        onClick={onClick}
        {...props}
      >
        {viewerIsFollowing && <CheckedIcon isSmall={isSmall} />}
        {children}
      </SmallStyledFollowButton>
    );
  }

  return (
    <StyledFollowButton
      viewerIsFollowing={viewerIsFollowing}
      onClick={onClick}
      {...props}
    >
      {viewerIsFollowing && <CheckedIcon isSmall={isSmall} />}
      {children}
    </StyledFollowButton>
  );
};

export default FollowButton;
