import {
  BodyText,
  Callout,
  Heading5,
} from '@/modules/design/components/textstyles';
import dayjs from 'dayjs';
import getConfig from 'next/config';
import { transparentize } from 'polished';
import { FC, useMemo } from 'react';
import styled from 'styled-components';
import TimeAgo from './TimeAgo';

const { publicRuntimeConfig } = getConfig();

const OuterContainer = styled.a`
  transition: background-color 0.2s ease-in;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => transparentize(0.98, theme.colors.CG1)};
  }

  &:active {
    background-color: ${({ theme }) => transparentize(0.92, theme.colors.CG1)};
  }

  &:last-child > div {
    border-bottom: none;
  }
`;

const InnerContainer = styled.div<{ isSmall: boolean }>`
  display: flex;
  padding: ${({ isSmall }) =>
    isSmall ? '12px 20px 12px 0' : '32px 32px 32px 0'};
  margin-left: ${({ isSmall }) => (isSmall ? '20px' : '32px')};
  flex-direction: row;
  align-items: start;
  border-bottom: 1px solid
    ${({ theme }) => transparentize(0.92, theme.colors.CG1)};
`;

const ProfileImage = styled.img`
  width: 56px;
  height: 56px;
  border-radius: 50%;
`;

const LabelsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 12px;
  flex: 1;
`;

const NotificationBody = styled(BodyText)`
  color: ${({ theme }) => theme.colors.CG1};
`;

const Caret = styled.img.attrs({
  src: require('@/modules/assets/icons/angle-right.svg'),
})`
  width: 8px;
  height: 14px;
  margin-left: 16px;
`;

const NotificationPreview: FC<{
  url: string;
  target?: string;
  imageSrc: string;
  title: string;
  message: string;
  sentOn?: string;
  isSmall: boolean;
  onClick?: () => void;
}> = ({ title, target, message, sentOn, url, imageSrc, isSmall, onClick }) => {
  const path = useMemo(() => {
    const webUrl = url.replace(
      `${publicRuntimeConfig.APP_DEEP_LINK_SCHEME}://`,
      'https://'
    );

    const anchorElement = document.createElement('a');
    anchorElement.href = webUrl;
    return anchorElement.href;
  }, [url]);

  return (
    <OuterContainer href={path} target={target} onClick={onClick}>
      <InnerContainer isSmall={isSmall}>
        <ProfileImage src={imageSrc} />
        <LabelsContainer>
          {title && <Heading5 as="p">{title}</Heading5>}
          <NotificationBody>{message}</NotificationBody>
          {sentOn ? (
            <Callout>
              <TimeAgo date={dayjs.utc(sentOn)} />
            </Callout>
          ) : null}
        </LabelsContainer>
        {!isSmall ? <Caret /> : null}
      </InnerContainer>
    </OuterContainer>
  );
};

export default NotificationPreview;
