import UserLink from '@/common/components/UserLink';
import { TodoContext } from '@/common/context/TodoContext';
import { ClosetTab } from '@/modules/user/components/ClosetTabs';
import Link from 'next/link';
import { FC, useContext } from 'react';
import styled from 'styled-components';
import MenuIconButton from './MenuIconButton';

const MenuIconWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  margin-right: 24px;
  height: 100%;

  @media (max-width: ${({ theme }) => theme.breakpoints.tabletMaxWidth}) {
    margin-right: 20px;
  }
`;

const TodoIcon: FC<{ userId: string }> = ({ userId }) => {
  const { todoState } = useContext(TodoContext);

  if (todoState.step >= 4 || todoState.isFinished || todoState.loading) {
    return (
      <UserLink userId={userId} tab={ClosetTab.Favorites} passHref>
        <MenuIconWrapper as={'a'}>
          <MenuIconButton
            icon={require('@/modules/assets/icons/favorites.svg')}
          />
        </MenuIconWrapper>
      </UserLink>
    );
  }
  return (
    <Link href={'/to-do'} passHref>
      <MenuIconWrapper as={'a'}>
        <MenuIconButton
          icon={require('@/modules/assets/icons/trophy-full.svg')}
          unreadCount={4 - todoState.step}
        />
      </MenuIconWrapper>
    </Link>
  );
};

export default TodoIcon;
