import ClosedTopMenu from '@/modules/closure/components/ClosedTopMenu';
import TopMenu from '@/modules/menu/components/TopMenu';
import { createRef, FC, PropsWithChildren, useContext, useEffect } from 'react';
import styled from 'styled-components';
import { ExperimentContext } from '../context/ExperimentContext';
import useInAppBrowser from '../hooks/useInAppBrowser';
import ThirdPartyScripts from './HtmlHead/ThirdPartyScripts';
import MobileOverlay from './MobileOverlay';

interface StyledMainProps {
  isInAppBrowser: boolean;
  isInClosedVariant: boolean;
}

const StyledMain = styled.main<StyledMainProps>`
  padding-top: ${({ theme, isInAppBrowser, isInClosedVariant }) =>
    isInAppBrowser
      ? 0
      : isInClosedVariant
      ? '52px'
      : theme.dimensions.appMenuBarHeight};

  &.no-transition * {
    transition: none !important;
  }
`;

const Layout: FC<PropsWithChildren> = ({ children }) => {
  const isInAppBrowser = useInAppBrowser();
  const mainRef = createRef<HTMLElement>();
  const { closure } = useContext(ExperimentContext);

  useEffect(() => {
    let timer: number;

    const handleResize = () => {
      if (timer) {
        clearTimeout(timer);
        timer = null;
      } else if (mainRef.current) {
        mainRef.current.classList.add('no-transition');
      }
      timer = window.setTimeout(() => {
        if (mainRef.current) {
          mainRef.current.classList.remove('no-transition');
        }
        timer = null;
      }, 100);
    };

    if (process.browser) {
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }
    return undefined;
  });

  return (
    <>
      <ThirdPartyScripts />
      <MobileOverlay />
      {isInAppBrowser ? null : closure.isInVariant ? (
        <ClosedTopMenu />
      ) : (
        <TopMenu />
      )}
      <StyledMain
        isInAppBrowser={isInAppBrowser}
        isInClosedVariant={closure.isInVariant}
        ref={mainRef}
      >
        {children}
      </StyledMain>
    </>
  );
};

export default Layout;
