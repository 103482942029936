import { Callout, Heading5 } from '@/modules/design/components/textstyles';
import dayjs from 'dayjs';
import Link from 'next/link';
import { transparentize } from 'polished';
import { FC } from 'react';
import styled from 'styled-components';
import { ChatFragment } from '../generated/graphql';
import TimeAgo from './TimeAgo';
import UnreadCount from './UnreadCount';

const OuterContainer = styled.a`
  transition: background-color 0.2s ease-in;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => transparentize(0.98, theme.colors.CG1)};
  }

  &:active {
    background-color: ${({ theme }) => transparentize(0.92, theme.colors.CG1)};
  }

  &:last-child > div {
    border-bottom: none;
  }
`;

const InnerContainer = styled.div<{ isSmall: boolean }>`
  display: flex;
  padding: ${({ isSmall }) =>
    isSmall ? '12px 20px 12px 0' : '32px 32px 32px 0'};
  margin-left: ${({ isSmall }) => (isSmall ? '20px' : '32px')};
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid
    ${({ theme }) => transparentize(0.92, theme.colors.CG1)};
`;

const ProfileImage = styled.img`
  width: 56px;
  height: 56px;
  border-radius: 50%;
`;

const LabelsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 12px;
  flex: 1;
  min-width: 0;
`;

const BottomLabels = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 2px;
`;

const ChatBody = styled(Callout)`
  flex: 1;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin-right: 8px;
`;

const TimeAgoLabel = styled(Callout)`
  flex-shrink: 0;
`;

const Caret = styled.img.attrs({
  src: require('@/modules/assets/icons/angle-right.svg'),
})`
  width: 8px;
  height: 14px;
  margin-left: 16px;
`;

const ChatPreview: FC<{ chat: ChatFragment; isSmall: boolean }> = ({
  chat,
  isSmall,
}) => {
  const message = chat.messagesConnection.nodes[0];
  return (
    <Link href={`/chat/${chat.user.id}`} passHref>
      <OuterContainer>
        <InnerContainer isSmall={isSmall}>
          <ProfileImage
            src={chat.user.profileImage.url}
            alt={chat.user.displayName}
          />
          <LabelsContainer>
            <Heading5>{chat.user.displayName}</Heading5>
            <BottomLabels>
              <ChatBody>{message.body}</ChatBody>
              <TimeAgoLabel>
                <TimeAgo date={dayjs.utc(message.sentOn)} />
              </TimeAgoLabel>
            </BottomLabels>
          </LabelsContainer>
          <UnreadCount count={chat.unreadCount} />
          {!isSmall ? <Caret /> : null}
        </InnerContainer>
      </OuterContainer>
    </Link>
  );
};

export default ChatPreview;
