import ContextMenu, {
  ContextMenuProps,
} from '@/common/components/ContextMenu/ContextMenu';
import { locales, supportedLanguages } from '@/common/constants';
import { LanguageContext } from '@/common/context/LanguageContext';
import { Language } from '@/common/generated/graphql';
import { translationKeyForLanguage } from '@/common/utils/translations';
import { transparentize } from 'polished';
import { FC, useContext } from 'react';
import styled from 'styled-components';
import { Heading5 } from '../../design/components/textstyles';

const StyledContextMenu = styled(ContextMenu)`
  height: 36px;
`;

const GlobeIcon = styled.img.attrs({
  src: require('@/modules/assets/icons/globe.svg'),
})`
  width: 24px;
  height: 24px;
`;

const LanguageTitle = styled(Heading5).attrs({ as: 'span' })`
  margin-left: 6px;
`;

const Button = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;

  &:before {
    content: '';
    display: block;
    background-color: transparent;
    position: absolute;
    left: -8px;
    right: -8px;
    top: 0;
    height: 100%;
    transition: background-color 0.2s ease-in;
    border-radius: 4px;
  }

  &:hover:before {
    background-color: ${({ theme }) => transparentize(0.98, theme.colors.CG1)};
  }

  &:active:before {
    background-color: ${({ theme }) => transparentize(0.92, theme.colors.CG1)};
  }
`;

const ExpandIcon = styled.img.attrs({
  src: require('@/modules/assets/icons/angle-down.svg'),
})``;

const StyledLanguageButton = styled(Heading5)<{ isSelected: boolean }>`
  display: block;
  padding: 8px 20px;
  width: 160px;
  font-weight: ${({ theme, isSelected }) =>
    isSelected ? theme.fontWeights.demi : theme.fontWeights.book};
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => transparentize(0.98, theme.colors.CG1)};
  }

  &:active {
    background-color: ${({ theme }) => transparentize(0.92, theme.colors.CG1)};
  }
`;

const LanguageMenu: FC<
  ContextMenuProps & {
    compact: boolean;
  }
> = ({ compact, ...props }) => {
  const {
    language: currentLanguage,
    translate,
    setLocale,
  } = useContext(LanguageContext);

  const changeLanguage = async (newLanguage: string) => {
    await setLocale(locales[newLanguage]);
  };

  return (
    <StyledContextMenu {...props}>
      <Button>
        {compact ? null : <GlobeIcon />}
        <LanguageTitle>
          {compact
            ? currentLanguage.toUpperCase()
            : translate(translationKeyForLanguage(currentLanguage as Language))}
        </LanguageTitle>
        <ExpandIcon />
      </Button>
      <>
        {supportedLanguages.map((language) => (
          <StyledLanguageButton
            as="span"
            key={language}
            isSelected={language === currentLanguage}
            onClick={() => changeLanguage(language)}
          >
            {translate(translationKeyForLanguage(language))}
          </StyledLanguageButton>
        ))}
      </>
    </StyledContextMenu>
  );
};

export default LanguageMenu;
