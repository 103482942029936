import NotificationPreview from '@/common/components/NotificationPreview';
import { EventsContext } from '@/common/context/EventsContext';
import { ExperimentContext } from '@/common/context/ExperimentContext';
import { LanguageContext } from '@/common/context/LanguageContext';
import {
  useMarkAllNotificationsAsReadMutation,
  useNotificationsQuery,
} from '@/common/generated/graphql';
import { getClosureUrl } from '@/common/utils/url';
import { ApolloError } from '@apollo/client';
import Link from 'next/link';
import { transparentize } from 'polished';
import { FC, forwardRef, useContext, useEffect } from 'react';
import styled from 'styled-components';
import Spinner from '../../design/components/spinner/Spinner';
import { Callout } from '../../design/components/textstyles';

const StyledNotifications = styled.section`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.CG7};
  width: 320px;
  max-height: calc(100vh - 52px);
  position: absolute;
  bottom: 0;
  left: 50%;
  box-shadow: 0 1px 8px 0 rgba(17, 17, 17, 0.16);
  border-radius: 2px;
  transform: translate(-50%, 100%);

  &:after {
    display: block;
    content: '';
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: -6px;
    border-bottom: 6px solid ${({ theme }) => theme.colors.CG7};
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobileMaxWidth}) {
    position: fixed;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 3;
    top: 50px;
    transform: none;
    max-height: none;

    &:after {
      display: none;
    }
  }
`;

const CenteredContainer = styled.div`
  height: 360px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  padding: 32px;
`;

const SeeAllButton = styled(Callout).attrs({ as: 'a' })`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  border-top: 1px solid ${({ theme }) => transparentize(0.92, theme.colors.CG1)};
`;

const Error: FC<{ error?: ApolloError }> = ({ error }) => {
  return <span>{error && error.message ? error.message : 'no data'}</span>;
};

const Notifications = forwardRef<HTMLElement, { className?: string }>(
  ({ className }, ref) => {
    const { translate, language } = useContext(LanguageContext);
    const { data, loading, error } = useNotificationsQuery({
      fetchPolicy: 'cache-first',
      variables: { first: 5 },
    });
    const [markNotificationsAsRead] = useMarkAllNotificationsAsReadMutation();
    const { restriction } = useContext(ExperimentContext);
    const { logEvent } = useContext(EventsContext);

    const handleOnLearnMoreClick = () => {
      logEvent('info_closure_banner');
    };

    const notifications = data?.viewer.notificationsConnection.nodes;

    useEffect(() => {
      markNotificationsAsRead();
    }, []);

    return (
      <StyledNotifications ref={ref} className={className}>
        {loading ? (
          <CenteredContainer>
            <Spinner wait={100} />
          </CenteredContainer>
        ) : error || !data ? (
          <Error error={error} />
        ) : !notifications.length ? (
          <CenteredContainer>
            <Callout>{translate('notifications_no_notifications')}</Callout>
          </CenteredContainer>
        ) : (
          <>
            <div>
              {restriction.isInVariant ? (
                <NotificationPreview
                  title={translate('closure_placeholder_title')}
                  imageSrc="/logo.png"
                  url={getClosureUrl(language)}
                  message={translate('closure_notification_expl')}
                  isSmall={true}
                  target="_blank"
                  onClick={handleOnLearnMoreClick}
                />
              ) : null}
              {notifications.map((notification) => (
                <NotificationPreview
                  key={notification.id}
                  title={notification.title}
                  url={notification.url}
                  message={notification.message}
                  imageSrc={notification.image.url}
                  sentOn={notification.sentOn}
                  isSmall={true}
                />
              ))}
            </div>
            <Link
              href={{ pathname: '/inbox', query: { tab: 'notifications' } }}
              passHref
            >
              <SeeAllButton>{translate('notifications_view_all')}</SeeAllButton>
            </Link>
          </>
        )}
      </StyledNotifications>
    );
  }
);
Notifications.displayName = 'Notifications';

export default Notifications;
