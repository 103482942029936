import styled from 'styled-components';
import SecondaryButton from './SecondaryButton';

const SmallSecondaryButton = styled(SecondaryButton)`
  height: 36px;
  min-height: 0;
  min-width: 0;
  font-size: 1.4rem;
  padding: 9px 11px;
`;

export default SmallSecondaryButton;
