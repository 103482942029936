import styled from 'styled-components';
import PrimaryButton from './PrimaryButton';

const XSmallPrimaryButton = styled(PrimaryButton)`
  height: 28px;
  min-height: 0;
  min-width: 0;
  font-size: 1.2rem;
  padding: 6px 8px;
`;

export default XSmallPrimaryButton;
