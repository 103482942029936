import { LanguageContext } from '@/common/context/LanguageContext';
import { StaticDataContext } from '@/common/context/StaticDataContext';
import { getHelpCenterUrl, getVacanciesUrl } from '@/common/utils/externalUrls';
import Link, { LinkProps } from 'next/link';
import { transparentize } from 'polished';
import { FC, PropsWithChildren, useContext, useState } from 'react';
import styled from 'styled-components';
import AuthLink from '../../auth/components/AuthLink';
import PrimaryButton from '../../design/components/buttons/PrimaryButton';
import SecondaryButton from '../../design/components/buttons/SecondaryButton';
import { Heading5, Heading6 } from '../../design/components/textstyles';
import { FilterLink } from '../../feed/components/ProductFilters/FilterLink';
import LanguageMenu from './LanguageMenu';
import MenuIconButton from './MenuIconButton';

const StyledMobileMenu = styled.div`
  position: relative;
  height: 100%;
  @media (min-width: ${({ theme }) => theme.breakpoints.tabletMaxWidth}) {
    display: none;
  }
`;

const StyledMenuIconButton = styled(MenuIconButton)`
  margin-right: 0;
`;

const ContextMenu = styled.div`
  position: fixed;
  top: 50px;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: ${({ theme }) => theme.colors.CG7};
  overflow-y: auto;
  overflow-x: hidden;
  border-top: 1px solid ${({ theme }) => transparentize(0.92, theme.colors.CG1)};
  display: flex;
  flex-direction: column;
  padding-bottom: 40px;
  z-index: 2;
`;

const ButtonsContainer = styled.div`
  display: grid;
  grid-gap: 12px;
  margin-left: 20px;
  border-bottom: 1px solid
    ${({ theme }) => transparentize(0.92, theme.colors.CG1)};
  padding: 24px 20px 32px 0;
`;

const Title = styled(Heading5)`
  margin: 32px 20px 8px 20px;
`;

const StyledButtonAnchor = styled(Heading6).attrs({ as: 'a' })`
  position: relative;
  display: block;
  padding: 16px 20px;
  color: ${({ theme }) => theme.colors.CG3};

  &:hover {
    background-color: ${({ theme }) => transparentize(0.98, theme.colors.CG1)};
  }

  &:active {
    background-color: ${({ theme }) => transparentize(0.92, theme.colors.CG1)};
  }

  &:after {
    content: '';
    position: absolute;
    left: 20px;
    right: 0;
    bottom: 0;
    height: 1px;
    background-color: ${({ theme }) => transparentize(0.92, theme.colors.CG1)};
  }
`;

const StyledLanguageMenu = styled(LanguageMenu)`
  padding: 16px 20px;
  height: auto;

  img:last-child {
    display: none;
  }

  &:after {
    content: '';
    position: absolute;
    left: 20px;
    right: 0;
    bottom: 0;
    height: 1px;
    background-color: ${({ theme }) => transparentize(0.92, theme.colors.CG1)};
  }
`;

type Props = Omit<LinkProps, 'passHref'> & {
  onClick?: () => void;
} & PropsWithChildren;

const MenuButton: FC<Props> = ({ children, onClick, ...props }) => (
  <Link {...props} passHref>
    <StyledButtonAnchor onClick={onClick}>{children}</StyledButtonAnchor>
  </Link>
);

const MobileMenu: FC = () => {
  const [isContextMenuOpened, setIsContextMenuOpened] = useState(false);
  const { language, translate } = useContext(LanguageContext);
  const { categories } = useContext(StaticDataContext);

  const closeContextMenu = () => setIsContextMenuOpened(false);

  return (
    <StyledMobileMenu>
      <StyledMenuIconButton
        icon={
          isContextMenuOpened
            ? require('@/modules/assets/icons/close.svg')
            : require('@/modules/assets/icons/hamburger-menu.svg')
        }
        onClick={() => setIsContextMenuOpened(!isContextMenuOpened)}
      />
      {isContextMenuOpened ? (
        <ContextMenu>
          <ButtonsContainer>
            <AuthLink isAuthorized={false} href="/add-product" passHref>
              <PrimaryButton as={'a'} onClick={closeContextMenu}>
                {translate('gen_sell_now')}
              </PrimaryButton>
            </AuthLink>
            <Link href={'/register'} passHref>
              <SecondaryButton as={'a'} onClick={closeContextMenu}>
                {translate('login_sign_up_login')}
              </SecondaryButton>
            </Link>
          </ButtonsContainer>
          <Title>{translate('gen_department')}</Title>
          {categories.map((category, index) => (
            <FilterLink
              key={index}
              filters={{ categoryId: category.id }}
              passHref
            >
              <StyledButtonAnchor onClick={closeContextMenu}>
                {category.title}
              </StyledButtonAnchor>
            </FilterLink>
          ))}
          <Title>{translate('gen_language')}</Title>
          <StyledLanguageMenu compact={false} alignContextMenu="bottom-left" />
          <Title>{translate('help_and_info')}</Title>
          <StyledButtonAnchor
            as="a"
            href={getHelpCenterUrl(language)}
            target="_blank"
            onClick={closeContextMenu}
          >
            {translate('help_center')}
          </StyledButtonAnchor>
          <MenuButton href={'/terms-and-conditions'} onClick={closeContextMenu}>
            {translate('tscs_tscs')}
          </MenuButton>
          <MenuButton href={'/privacy-statement'} onClick={closeContextMenu}>
            {translate('tscs_privacy_policy')}
          </MenuButton>
          <MenuButton href={'/cookie-statement'} onClick={closeContextMenu}>
            {translate('tscs_cookie_statement')}
          </MenuButton>
          <Title>{translate('footer_other')}</Title>
          <MenuButton href={'/'} onClick={closeContextMenu}>
            {translate('home_home')}
          </MenuButton>
          <MenuButton href={'/about'} onClick={closeContextMenu}>
            {translate('about_about_us')}
          </MenuButton>
          <MenuButton href={'/blog'} onClick={closeContextMenu}>
            {translate('blog_blog')}
          </MenuButton>
          <StyledButtonAnchor
            as="a"
            href={getVacanciesUrl()}
            target="_blank"
            rel="nofollow"
            onClick={closeContextMenu}
          >
            {translate('about_vacancies')}
          </StyledButtonAnchor>
          <MenuButton href={'/register'} onClick={closeContextMenu}>
            {translate('login_register')}
          </MenuButton>
        </ContextMenu>
      ) : null}
    </StyledMobileMenu>
  );
};

export default MobileMenu;
